import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/main.scss";
import App from "./App";
import Header from "./components/Layout/Header/Header";
import Footer from "./components/Layout/Footer/Footer";
import { BrowserRouter } from "react-router-dom";
import { PrismicProvider } from "@prismicio/react";
import { client } from "./prismic";
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <Helmet>
      <title>Esquare Info</title>
      <meta
        name="description"
        content="Esquare is a software solution provider, specialized in the design and development of custom solutions for web poratl and mobile applications."
      />
      <meta
        name="keywords"
        content="Liferay, Liferay Consulting, Java, EaquareInfo, React"
      />
    </Helmet>
    <Header parentMenu="home" />

    <PrismicProvider client={client}>
      <App />
    </PrismicProvider>

    <Footer footerClass="rs-footer home9-style main-home" />
  </BrowserRouter>
);

