import { Route, Routes } from "react-router-dom";

import Home from "./components/Pages/Home/Home";
import Contact from "./components/Pages/Contact/Contact";
import WebDesign from "./components/Pages/Services/WebDesign";
import MobileDevelop from "./components/Pages/Services/MobileDevelp";
import CorporateTraining from "./components/Pages/Services/CorporateTrainig";
import BusinessConsulting from "./components/Pages/Services/BusinessConsulting";
import Portal from "./components/Pages/Solutions/Portal";
import WebPortal from "./components/Pages/Services/WebPortal";
import CloudComputing from "./components/Pages/Solutions/CloudCoputing";
import ContentManagement from "./components/Pages/Solutions/ContentManagement";
import Ecommerce from "./components/Pages/Solutions/Ecommerce";
import BusinessManagement from "./components/Pages/Solutions/BusinessManagement";
import Company from "./components/Pages/About/Company";
import OutSourcingPartner from "./components/Pages/About/OutsourcingPartner";
import MarketingPartner from "./components/Pages/About/MarketingPartner";
import BecamePartner from "./components/Pages/About/BecamePartner";
import Liferay from "./components/Pages/Technologies/Liferay";
import Careers from "./components/Pages/Careers/Careers";
import Travel from "./components/Pages/Travel/Travel";
import HealthCare from "./components/Pages/HealthCare/HealthCare";
import Education from "./components/Pages/Education/Education";
import HRMS from "./components/Pages/HRMS/HRMS";
import CaseStudy from "./components/Pages/ShowCase/CaseStudies/CaseStudy";
import Android from "./components/Pages/Technologies/Android/Android";
import Brochure from "./components/Pages/ShowCase/Brochure";
import Presentation from "./components/Pages/ShowCase/Presentation";
import Clientele from "./components/Pages/ShowCase/Clientele";
import Meetingscheduler from "./components/Pages/ShowCase/CaseStudies/Meetingschedule";
import TripPlanner from "./components/Pages/ShowCase/CaseStudies/Tripplanner";
import Enterprisehealth from "./components/Pages/ShowCase/CaseStudies/Enterprisehealth";
import Companyportal from "./components/Pages/ShowCase/CaseStudies/Companyportal";
import RoadAccident from "./components/Pages/ShowCase/CaseStudies/Roadaccident";
import MobileApplication from "./components/Pages/ShowCase/CaseStudies/Mobileapplication";
import WeatherApp from "./components/Pages/ShowCase/CaseStudies/Weather";
import MultiLingual from "./components/Pages/ShowCase/CaseStudies/Multilingual";
import CloudService from "./components/Pages/ShowCase/CaseStudies/Cloudservice";
import Security from "./components/Pages/ShowCase/CaseStudies/Security";
import Budgetplanning from "./components/Pages/ShowCase/CaseStudies/Budgetplanning";
import Blogs from "./components/Pages/Blog/Blogs"; // no prismic
import Blog1 from "./components/Pages/Blog/InnerPages/Blog1"; // no prismic
import Java from "./components/Pages/Technologies/Java";
import Reacts from "./components/Pages/Technologies/React";
import Angular from "./components/Pages/Technologies/Angular";
import Net from "./components/Pages/Technologies/Net";
import SpringBoot from "./components/Pages/Technologies/SpringBoot";
import MicroServices from "./components/Pages/Technologies/MicroServices";
import Partner from "./components/Pages/Partner/Partner"; // no prismic

const route = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/Contact" element={<Contact />}></Route>
      <Route path="/WebPortal" element={<WebPortal />}></Route>
      <Route path="/WebDesign" element={<WebDesign />}></Route>
      <Route path="/MobileDevelopment" element={<MobileDevelop />}></Route>
      <Route path="/CorporateTraining" element={<CorporateTraining />}></Route>
      <Route
        path="/BusinessConsulting"
        element={<BusinessConsulting />}
      ></Route>
      <Route path="/Portal" element={<Portal />}></Route>
      <Route path="/CloudComputing" element={<CloudComputing />}></Route>
      <Route path="/ContentManagement" element={<ContentManagement />}></Route>
      <Route path="/E-commerce" element={<Ecommerce />}></Route>
      <Route
        path="/Business-Management"
        element={<BusinessManagement />}
      ></Route>
      <Route path="/About-Us" element={<Company />}></Route>
      <Route
        path="/Outsourcing-Partners"
        element={<OutSourcingPartner />}
      ></Route>
      <Route path="/Marketing-Partners" element={<MarketingPartner />}></Route>
      <Route path="/Become-A-Partner" element={<BecamePartner />}></Route>
      <Route path="/Liferay" element={<Liferay />}></Route>
      <Route path="/Careers" element={<Careers />}></Route>
      <Route path="/Travel" element={<Travel />}></Route>
      <Route path="/HealthCare" element={<HealthCare />}></Route>
      <Route path="/Education" element={<Education />}></Route>
      <Route path="/HRMS" element={<HRMS />}></Route>
      <Route path="/Case-Studies" element={<CaseStudy />}></Route>
      <Route path="/Partners" element={<Partner />}></Route>
      <Route path="/Android" element={<Android />}></Route>
      <Route path="/Brochure" element={<Brochure />}></Route>
      <Route path="/Presentation" element={<Presentation />}></Route>
      <Route path="/Clientele" element={<Clientele />}></Route>
      <Route path="/Blogs" element={<Blogs />}></Route>
      <Route path="/Java" element={<Java />}></Route>
      <Route path="/React" element={<Reacts />}></Route>
      <Route path="/Angular" element={<Angular />}></Route>
      <Route path="/.Net" element={<Net />}></Route>
      <Route path="/SpringBoot" element={<SpringBoot />}></Route>
      <Route path="/MicroServices" element={<MicroServices />}></Route>

      {/* Case Studies Routes Start-Here */}
      <Route path="/case-Studies/Case1" element={<Meetingscheduler />}></Route>
      <Route path="/case-Studies/Case2" element={<TripPlanner />}></Route>
      <Route path="/case-Studies/Case3" element={<Enterprisehealth />}></Route>
      <Route path="/case-Studies/Case4" element={<Companyportal />}></Route>
      <Route path="/case-Studies/Case5" element={<RoadAccident />}></Route>
      <Route path="/case-Studies/Case6" element={<MobileApplication />}></Route>
      <Route path="/case-Studies/Case7" element={<WeatherApp />}></Route>
      <Route path="/case-Studies/Case8" element={<MultiLingual />}></Route>
      <Route path="/case-Studies/Case9" element={<CloudService />}></Route>
      <Route path="/case-Studies/Case10" element={<Security />}></Route>
      <Route path="/case-Studies/Case11" element={<Budgetplanning />}></Route>

      {/* Case Studies Routes End-Here */}

      {/* Blog Routes Start-Here */}
      <Route path="/blog1" element={<Blog1 />}></Route>
      {/* Blog Routes End-Here */}
    </Routes>
  );
};

export default route;
