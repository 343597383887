import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Aos from "aos";
import SiteBreadcrumb from "../../BreadCumb/SiteBreadcrumb";

const Partner = () => {
  useEffect(() => {
    Aos.init();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const DisplayNone = {
    display: "none",
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Partners</title>
        <meta
          name="description"
          content="Explore our case studies to see how our solutions have helped businesses overcome their challenges and achieve their goals."
        />
        <link rel="canonical" href="http://esquareinfo.com/Case-Studies" />
      </Helmet>

      <SiteBreadcrumb
        pageTitle={"Partners"}
        displayNone={DisplayNone}
        breadcrumbsImg={"/partner-banner.png"}
      />
      <div
        className="noraml-Text main-home event-bg pt-40 pb-1 md-pt-10 md-pb-110"
        style={{ background: "#ffffff" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-7 col-md-6 mb-20"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h2 style={{ color: "	#800000" }}>
                MEG: Elevating Healthcare Quality Management
              </h2>

              <p className="textStyle">
                MEG is an innovative cloud-based quality management system for
                healthcare. Designed to elevate care quality and patient safety,
                the platform also simplifies the achievement of regulatory and
                accreditation compliance across various care settings.
              </p>
              <p className="textStyle">
                Empowering healthcare providers with streamlined tools, MEG
                enables them to manage, analyse, and act upon vital information.
                Many leading providers attest to MEG’s platform&#39;s
                unparalleled impact, highlighting an impressive tenfold rise in
                staff productivity following implementation.
              </p>
              <p className="textStyle">
                Frontline professionals can effortlessly capture and access this
                information from any location or device, enhancing key functions
                such as Audit/Compliance, Incident Reporting, Risk Management,
                and Patient Experience. Leadership teams benefit from a
                real-time view of data aggregated from multiple sources and
                unified in a single location, spanning various sites.
              </p>
              <p className="textStyle">
                With the trust of hospitals in 20+ countries and multi-language
                support, MEG stands at the forefront of healthcare quality
                management. Dive deeper at{" "}
                <a href="https://megit.com/" target="_blank" rel="noreferrer">
                  www.megit.com.
                </a>
              </p>
            </div>

            <div className="col-lg-5 col-md-6  my-auto">
              <div
                data-aos="flip-left"
                data-aos-duration="1500"
                data-aos-delay="280"
              >
                <a href="https://megit.com/" target="_blank" rel="noreferrer">
                  <img
                    src={"..//MEG_Logo.png"}
                    alt="Main-img"
                    className="pl-30 lg-pl-10 pb-30"
                    style={{ borderRadius: "60px", width: "100%" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Partner;
