import React from "react";
import Routess from "./Route";
import "aos/dist/aos.css";
import "aos/dist/aos.js";

const App = () => {
  return (
    <React.Fragment>
      <Routess />
    </React.Fragment>
  );
};

export default App;
